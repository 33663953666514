<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        On a planet in a different solar system, silver has an atomic weight of
        <number-value :value="AgWeight" unit="\text{g/mol}" />.
      </p>

      <p class="mb-3">
        a) Much like on Earth, silver has two isotopes on this planet. Using the atomic weights of
        these two isotopes shown below, determine the abundance of each of these isotopes on this
        distant planet.
      </p>

      <p class="mb-4 ml-10">
        <v-simple-table style="width: 350px">
          <thead>
            <tr>
              <th style="font-size: 15px">Isotope</th>
              <th style="font-size: 15px; text-align: center">Atomic weight (g/mol)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <stemble-latex content="$^{107}\text{Ag}$" />
              </td>
              <td style="text-align: center">
                <stemble-latex content="$106.91$" />
              </td>
            </tr>
            <tr>
              <td>
                <stemble-latex content="$^{109}\text{Ag}$" />
              </td>
              <td style="text-align: center">
                <stemble-latex content="$108.90$" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-2"
        prepend-text="$^{107}\text{Ag}$"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.input2"
        class="mb-8"
        prepend-text="$^{109}\text{Ag}$"
        append-text="$\text{\%}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) The coins on this planet are made entirely of silver! Determine the number of atoms of
        silver in a
        <number-value :value="coinMass" unit="\text{g}" />
        coin in this other world.
      </p>
      <calculation-input
        v-model="inputs.input3"
        class="mb-2"
        append-text="$\text{atoms}$"
        show-helpful-hint
        helpful-hint-position="below"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question205',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
    };
  },
  computed: {
    AgWeight() {
      return this.taskState.getValueBySymbol('AgWeight').content;
    },
    coinMass() {
      return this.taskState.getValueBySymbol('coinMass').content;
    },
  },
};
</script>
